import { css } from '@emotion/core';

export const wrapper = css`
  max-width: 980px;
  margin: 0 auto;
`;

export const subtitle = css`
  padding-bottom: 0.75rem;
`;
