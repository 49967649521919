import React from 'react';

import Layout from '../global/Layout';
import Hero from '../global/Hero';
import Faq from './Faq';
import Schedule from './Schedule';

const Entry = () => {
  return (
    <Layout>
      <Hero title="Free Tastings" subtitle="Liquor, wine &amp; beer!" />
      <Faq />
      <Schedule />
    </Layout>
  );
};

export default Entry;
