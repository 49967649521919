import React from 'react';
import { Link } from 'gatsby';

import { getRouteDefinitions } from '../../routes';

const routes = getRouteDefinitions();

const Schedule = () => (
  <section className="hero has-text-centered">
    <div className="hero-body">
      <div className="container">
        <Link className="button is-outlined is-primary" to={routes.contact}>
          Call For Schedule
        </Link>
      </div>
    </div>
  </section>
);

export default Schedule;
