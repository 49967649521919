import React from 'react';
import SectionContainer from '../global/SectionContainer';

import * as styles from './Faq.style.js';

const Faq = () => (
  <>
    <SectionContainer>
      <section class="hero has-text-centered">
        <div class="hero-body">
          <div class="container">
            <h2 class="subtitle is-4">Frequently Asked Questions</h2>
          </div>
        </div>
      </section>
      <div css={styles.wrapper}>
        <div className="columns">
          <div className="column">
            <h3 className="subtitle is-5 is-marginless" css={styles.subtitle}>
              Is Free Tasting Legal?
            </h3>
            <p>
              YES, we are one of the very few in the whole State that is
              licensed to hold tastings for any alcohol product. Most stores, if
              they have any tastings at all, will only hold beer and wine
              tastings and charge you for it. We have the new type of license
              that allows us to educate and taste our customers on every alcohol
              product we sell… absolutely free of charge.
            </p>
          </div>
          <div className="column">
            <h3 className="subtitle is-5 is-marginless" css={styles.subtitle}>
              Why Don’t Other Stores Do This?
            </h3>
            <p>
              We are one of the largest independent operators of alcoholic
              beverage stores in the State of California. 80% of what we handle
              is not available in 99% of our competitor stores. Free tasting is
              no different and we offer this free service to provide our
              customers with a better and more fulfilling shopping experience.
            </p>
          </div>
        </div>
      </div>
      <section class="hero has-text-centered">
        <div class="hero-body">
          <div class="container">
            <h2 class="subtitle is-4">When Do We Hold Our Tastings?</h2>
          </div>
        </div>
      </section>
      <div css={styles.wrapper}>
        <div className="columns">
          <div className="column">
            <h3 className="subtitle is-5 is-marginless" css={styles.subtitle}>
              Regularly Scheduled Tastings
            </h3>
            <p>
              Regularly scheduled tastings are held almost every Friday and
              Saturday from 4-7PM. In these tastings, usually companies will
              come for the specific time-slot and taste you on their own
              products.
            </p>
          </div>
          <div className="column">
            <h3 className="subtitle is-5 is-marginless" css={styles.subtitle}>
              Customer Request
            </h3>
            <p>
              A group of customers come to the store and ask us to taste a
              certain type of product. If we have the time and the supplier
              approves it, we will hold the tasting.
            </p>
          </div>
        </div>
      </div>
    </SectionContainer>
  </>
);

export default Faq;
